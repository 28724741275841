export interface CallLightData {
  callLightStatus: boolean;
  lastUpdatedBy: string;
  lastUpdatedByName?: string;
  alertType: AlertType;
  lastUpdatedDateTime: string;
  activatedDateTime: string;
}

export enum AlertType {
  Default,
  Reminder,
  Urgent,
  Cancel
}
