<div>
  <textarea
    title="Text Area"
    matInput
    [id]="id"
    [readonly]="props.readonly"
    [required]="props.required || false"
    [formControl]="formControl"
    [cols]="props.cols"
    [rows]="props.rows"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder ?? ''"
    [tabindex]="props.tabindex"
    [cdkTextareaAutosize]="props.autosize"
    [cdkAutosizeMinRows]="props.autosizeMinRows"
    [cdkAutosizeMaxRows]="props.autosizeMaxRows"
    [class.cdk-textarea-autosize]="props.autosize"
    [errorStateMatcher]="textAreaErrorMatcher"
    (keydown.enter)="preventEnter($event, props)"
    #text
  ></textarea>
  <mat-hint *ngIf="props.maxLength" flexLayout="row" flexLayoutAlign="end"
    >{{ text.value.length }} / {{ props.maxLength }}</mat-hint
  >
</div>
