<mat-form-field flex="100">
  <mat-label>{{ props.label }}</mat-label>

  <!-- Render input for text input -->
  @if (props.type === "text") {
    <input [formControl]="formControl" matInput [required]="props.required ? true : false" />
  }

  <!-- Render mat-date-picker for date field -->
  @if (props.type === "date") {
    <input
      matInput
      [formControl]="formControl"
      [required]="props.required ? true : false"
      [max]="maxDate"
      [matDatepicker]="picker"
      (dateChange)="formatDate($event)"
    />
    <mat-datepicker #picker></mat-datepicker>
  }
  @if (props.type === "date") {
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
  }

  <!-- Render phone input for phone num field -->
  @if (props.type === "phone") {
    <vp-phone-input [formControl]="formControl"></vp-phone-input>
  }

  <!-- Render textarea for textarea field -->
  @if (props.type === "textarea") {
    <textarea
      #text
      matInput
      [cols]="props.cols"
      [rows]="props.rows"
      [formControl]="formControl"
      [required]="props.required || false"
      [placeholder]="props.placeholder ?? ''"
      [cdkTextareaAutosize]="props.autosize"
      [cdkAutosizeMinRows]="props.autosizeMinRows"
      [cdkAutosizeMaxRows]="props.autosizeMaxRows"
      [class.cdk-textarea-autosize]="props.autosize"
    ></textarea>
    <mat-hint *ngIf="props.maxLength" flexLayout="row" flexLayoutAlign="end" align="end"
      >{{ text.value.length }} / {{ props.maxLength }}</mat-hint
    >
  }

  <!-- Render mat-select for dropdown selector -->
  @if (props.type === "select") {
    <mat-select
      [formControl]="formControl"
      [required]="props.required ? true : false"
      [multiple]="props.multiple"
    >
      <mat-option *ngFor="let option of selOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  }

  <mat-hint>{{ props.description }}</mat-hint>
  @if (formControl.hasError("required")) {
    <mat-error>This field is required</mat-error>
  }
</mat-form-field>
